const backendURL = "https://suits-backend-jey7.vercel.app"

export const AppointReq = async(data)=>{
    try{
        const headers = {
            "Content-Type" : "application/json"
        }        


        const resp = await fetch(`${backendURL}/sendMail`, {method : 'POST',headers:headers,body:JSON.stringify({ ...{type:2}, ...data })})
        const res = await resp.json()

        return res.status === 0 ? true : false
    }catch(e){
        console.log(e)
    }
}
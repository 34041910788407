import { useEffect, useRef, useState } from 'react';
import cart from '../assets/icon/addCart.png'

import aim from '../assets/samples/anima.gif'
import { useNavigate } from 'react-router-dom';
function P_card({item}) {


    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

    const [load, setLoad] = useState(true)


    const [uni, setUni] = useState()

    useEffect(()=>{
        var a = [];
        item.fabrics.forEach(element => {
            if(!a.includes(element.type)){
                a.push(element.type)
            }
        });
        setUni(a)
    }, [])
    const navigate = useNavigate()

    const counter = useRef(0);
    const imageLoaded = () => {
        counter.current += 1;
        let L = 1;
        
        if (counter.current >= L) {
            setLoad(false);
            counter.current = 0;
        }
    }
    return ( 
    <div className="p-item-card">
        
        <div className="preset_img"
            onClick={()=>{
                navigate("/test3", { state: { sel: item } });
            }}
        >
            {(load) && 
            <div style={{width:"100%", height:"100%", backgroundColor:"white", position:"absolute"}}>
                <img src={aim} style={{width:"70px"}} className='both_center'/>
            </div>
            }

            {!load && <img src={item.src} type="button"
                className='preset_main_img'
                onLoad={imageLoaded}
            />}
        </div>
        
        <div className="presets_bottom" type="button"
            onClick={()=>{
                // navigate("/test3", { state: { sel: item } });
            }}
        >
            <div style={{position:"relative"}}>
                <label className="txt-font"
                    style={isMobile ? {width:"80%"} : {width:"80%"}}
                    onClick={()=>{
                        navigate("/test3", { state: { sel: item } });
                    }}
                >
                    {item.name}
                </label>
                <img src={cart} style={{width:"25px", position:"absolute", right:"0"}} type="button"
                    onLoad={imageLoaded}
                    onClick={()=>{
                        sessionStorage.setItem('sel',JSON.stringify({...item, ...{item_type:1}}))
                        navigate("/measurement")
                        console.log("Clicking on cart")
                    }}
                />
            </div>
            <label>
                {uni && uni.map((typ_val, k)=>{
                    return(<span>{typ_val}  {k<uni.length-1 ?<>,</>:<></>} </span>)
                })}
            </label>
            <div
                onClick={()=>{
                    navigate("/test3", { state: { sel: item } });
                }}
            > 

                
                {item.discount && Number(item.discount)>0 ?
                    <>
                    <span className='txt-font1'>{(Number(item.price) - Number(item.price)*(Number(item.discount)/100)).toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                    })}</span>
                    &nbsp;&nbsp;&nbsp;
                    <span style={{fontSize:"1rem", opacity:"1", textDecoration:"line-through"}}>
                        
                        {
                            Number(item.price).toLocaleString('en-IN', {
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                            })
                        }  
                    </span>
                    &nbsp;&nbsp;
                    <label style={{backgroundColor:"green", "color":"white", padding:"2px 5px"}} 
                        className='txt-small'
                    > {item.discount} % off</label>

                    </>
                    : <>
                    <span className='txt-font1'>
                        {
                            Number(item.price).toLocaleString('en-IN', {
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                            })
                        }  
                    </span>
                    {isMobile && <><br/><br/></>}
                    </>
                }
            
            </div>

            {item.name.length < 15 ? <><br/></> : <></>}
        </div>

    </div> );
}

export default P_card;
import { useEffect, useState } from 'react';
import suit from '../assets/samples/s1.png'

import minus from '../assets/icon/minus.png'
import plus from '../assets/icon/plus1.png'
import Foot from '../footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { Carousel, Modal } from 'react-bootstrap';
import React from 'react';

function Preset_item() {

    const navigate = useNavigate()
    const [Data , setData] = useState()
    const location = useLocation()

    const [currH, setCurrH] = useState()

    const [fabDets,setFabDets] = useState()

    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

    useEffect(()=>{
        if(location && location.state && location.state["sel"]){
            setData(location.state["sel"])
            getPresetFabric(location.state["sel"])
            console.log(location.state["sel"])
        }
    }, [])
    const [QTY, setQty] = useState(1)

    const handleClick = (val)=>{
        if(val === 0){
            if(QTY != 0){
                setQty(QTY - 1)    
            }
        }else{
            setQty(QTY + 1)
        }
    }
    
    const [modalShow, setModalShow] = React.useState(false);

    const getPresetFabric = (data)=>{

        try{

            var Fabric = {}

            for(let i=0;i<data.fabrics.length;i++){
                if( !Object.keys(Fabric).includes(data.fabrics[i].type) ){
                    var col = ""
                    // data.fabrics[i].color.forEach(element => {
                    //     col+=element+","
                    // });
                    Fabric[data.fabrics[i].type] = data.fabrics[i]
                    // Fabric[data.fabrics[i].type].color = col
                }
            }
            console.log(Fabric)
            setFabDets(Fabric)
            setCurrH(Object.keys(Fabric)[0])

        }catch(e){
            console.log(e)
        }
    }


    return ( 
    <div className="pre-item">
        <div className="pre-sec-wrap">
        {/* <div className='carousel-img-main'> */}
            <Carousel variant='dark' fade className="carousel-img-main">
                {Data && Data.single && Data.single.map((val)=>{

                    return(
                        <Carousel.Item className="carousel-img-items">
                            <div className="">
                                <img src={val} className="pre-item-img"/>
                            </div>
                        </Carousel.Item>
                    )
                })

                }
                
                {/* <Carousel.Item>
                    <div className="">
                        <img src={"https://img.freepik.com/premium-photo/off-white-corporate-coat-black-mannequin_126745-1394.jpg"} className="pre-item-img"/>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="">
                        <img src={"https://images-static.nykaa.com/media/catalog/product/d/8/d894058CLUBM00000160_.jpg?tr=w-500"} className="pre-item-img"/>
                    </div>
                </Carousel.Item> */}
            </Carousel>

            {/* <div>ewfewfwefwefwef</div>
            </div> */}
            <div className="pre-item-right">
                <div className="items-btns-info">
                    <div className="price-info-container">
                        <div className="title-font item-title">
                            {Data ? Data["name"] :"Example Suite" }
                        </div>
                        <div className='dot'>
                        •
                        </div>
                        <div className="item-pricing-sm">
                        
                            {Data ? `${Number(Data["price"]).toLocaleString('en-IN', {
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                            })}` :"Example Suite" }
                        </div>
                    </div>
                    <div className="txt-font item-pricing">
                        <span
                            className={Data && Data.discount && Number(Data.discount) > 0 ? "discount_is" : ""}
                        >{Data ? `${Number(Data.price).toLocaleString('en-IN', {
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                            })}` :"Example Suite" }
                        </span>
                        {Data && Data.discount && Number(Data.discount) > 0 && 
                        <span className='discount_txt'>
                                {(Number(Data.price) - (Number(Data.price)*Number(Data.discount))/100).toLocaleString('en-IN', {
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                            })}
                            {"  "}
                            <span style={{color:"green", fontSize:"1rem"}} >Save {Number(Data.discount)}%</span>
                        </span>
                        }
                    </div>
                    <b>Item Includes : {fabDets && Object.keys(fabDets).map((val)=>{return(<>{val}{" , "}</>)})}</b>
                    {/* <br/><br/> */}
                    <div className="pre-item-des">
                        I'm a product description. I'm a great place to add more details about your product such as sizing, material, care instructions and cleaning instructions.
                    </div>
                    <div
                        className='hori_center'
                        style={{"display":"flex", "gap":"10px"}}
                    >
                    <div className='quantity item-counter'>
                        <div className='quantity_buts'>
                            <img src={minus} className='qty_butts' type="button"
                                style={QTY === 0 ? {"opacity":"0.2"}:{}}
                                onClick={()=>{
                                    handleClick(0)
                                }}
                            />
                        </div>
                        <div className='qty_des txt-font1'>{QTY}</div>
                        <div className='quantity_buts'>
                            <img src={plus} className='qty_butts' type="button"
                                onClick={()=>{
                                    handleClick(1)
                                }}
                            />
                        </div>
                    </div>
                    <button className="Butt1 add-cart"
                        onClick={()=>{
                            sessionStorage.setItem('sel',JSON.stringify({...Data, ...{item_type:1}}))
                            navigate("/measurement")
                        }}
                    >
                        <span>Get Measured</span></button>
                    <button className='Butt1 add-cart' style={{"marginRight":"3vw"}}><span>Share</span></button>
                    </div>
                    {/* <div className="txt-font product-info"><span>Product Info</span></div> */}
                    {/* <div className="pre-product-des">
                        I'm a product detail. I'm a great place to add more information about your product such as sizing, material, care and cleaning instructions. This is also a great space to write what makes this product special and how your customers can benefit from this item.
                    </div> */}
                </div>
                <br/><div className='txt-font'
                    style={isMobile ? {textAlign:"center"}:{}}>
                Fabric Details</div>
                {fabDets && currH && 
                <div className='preset_fabric_details'>
                    <div className='p_f_d_t txt-font1'
                        style={{width:"100%"}}
                    >
                        {Object.keys(fabDets).map((val1)=>{return(<div className={ currH === val1 ? "h_p_h p_h_i" : "p_h_i" } 
                            style={{width:`${100/Object.keys(fabDets).length}%`}}
                            onClick={()=>{
                                setCurrH(val1)
                            }}
                            type="button"
                        >{val1}</div>)})}
                    </div>

                    <div className='fab_p' style={{display:"flex"}}>
                        <div className='fab_p_div_1'
                        style={{"width":"55%", marginRight:"5%"}}>
                            <img src={
                             fabDets[currH]["single"] && fabDets[currH]["single"].length>0 ?fabDets[currH]["single"][0] :fabDets[currH]["src"]
                            } className='p_f_d_img'/>
                        </div>
                        <div 
                        className='fab_p_div_2'
                        style={{"width":"40%", display:"flex", flexDirection:"column"}}>
                            
                            <div className='FIT_H FIT_W both_center fab_p_div_3'
                                style={{display:"flex", flexDirection:"column"}}
                            >
                            <div className='txt-font1 FIT_W'><b>{currH}</b></div><br/>
                            <div style={{display:"flex"}} className='FIT_W'>
                                <img src={fabDets[currH].brandImg} style={{"width":"80px"}}/>
                                <span className='p_fab_n'>{fabDets[currH].name}</span>
                            </div>
                            
                            <span className='FIT_W'>{fabDets[currH].material}</span>
                            <span className='FIT_W'>Pattern : {fabDets[currH].pattern}</span>
                            <span className='FIT_W'>Weave : {fabDets[currH].weave}</span>
                            <span className='FIT_W'>Width : {fabDets[currH].width}</span><br/>
                            
                            </div>

                        </div>

                        <span className='card_des_f hori_center' style={{fontSize:"0.7rem"}}> {fabDets[currH].des} </span>
                        {/* <div className='p_fab_war'>weoifnwefpwefm ewfmwefewvjj jjjjjjjjjjjjj jjjjj j jjjjjjjj jjjjj jjjjj jjjjjjjjjj</div> */}
                    </div>
                    
                </div>}
                <br/>
            </div>
            <Modal
                id="item-info-modal"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
                >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                    {Data ? Data["name"] :"Example Suite" }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>
                        {Data ? `${'$'}${Data["price"]}` :"Example Suite" }
                    </h4>
                    <p>
                    I'm a product description. I'm a great place to add more details about your product such as sizing, material, care instructions and cleaning instructions.
                    </p>
                </Modal.Body>
            </Modal>
        </div>
        <div>
            <Foot/>
        </div>
    </div> );
}

export default Preset_item;
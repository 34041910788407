import "./presets_main.css";
import { useEffect, useState } from "react";
import P1 from "../assets/suits/product-1.jpg";
import P2 from "../assets/suits/uniform.jpg";
import P3 from "../assets/suits/pant_shirt.jpg";
import { PreFilter } from "../Data";
import { Sample_preset } from "../Data";
import mbg from "../assets/mbg1.jpg";
import Foot from "../footer";
import { useNavigate } from "react-router-dom";
import { getData, getDataFromCollection } from "../Firebase/firestoreGet";
import { db } from "../firebase";
import P_card from "./preset-card";

import aim from '../assets/samples/anima.gif'

function Presets_main() {
  const navigate = useNavigate();
  // const presets = Sample_preset;

  const [presets, setPresets] = useState()

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(8);

  // const lastPostIndex = currentPage * postsPerPage;
  // const firstPostIndex = lastPostIndex - postsPerPage;
  const [currentPosts, setCurrrentPosts] = useState()

  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

  // presets.slice(firstPostIndex, lastPostIndex);

  const [pages , setPages] = useState([])

  const getPresets  = async()=>{
    try{

      const collection = db.collection("Presets")

      const Data = await getData(collection)

      console.log(Data)

      return Data;
    }catch(e){
      console.log(e)
    }
  }
  useEffect(()=>{
    getPresets().then((data)=>{
      const lastPostIndex = currentPage * postsPerPage;
      const firstPostIndex = lastPostIndex - postsPerPage;
      setCurrrentPosts(data.slice(firstPostIndex, lastPostIndex))
      setPresets(data)

      var a = []
      for (let i = 1; i <= Math.ceil(data.length / postsPerPage); i++) {
        a.push(i);
      }
      setPages(a)
    })
  }, [])

  useEffect(()=>{
    if(presets){
      const lastPostIndex = currentPage * postsPerPage;
      const firstPostIndex = lastPostIndex - postsPerPage;
      setCurrrentPosts(presets.slice(firstPostIndex, lastPostIndex))
    }
  }, [currentPage])

  return (
    <div className="presets_main">
        

        <div style={{textAlign:"center", marginTop:"80px", marginBottom:"20px"}}>
          <div className="p_main_title hori_center">
            <b>Style Made Simple: Ready-to-Wear, Ready for You!</b>
          </div>
          <div className="txt-small p_m_des_main hori_center">
            Whether you’re dressing up for an event or keeping it casual, we’ve got the perfect outfit ready and waiting. Discover trendy, high-quality clothing that fits your style—no hassle, no wait!
          </div>
          
        </div>
        
        <div style={{position:"relative", display:"flex", width:"80%"}} className="hori_center">
          
        Type &nbsp;
        <select name="cars" id="cars">
          <option value="volvo">All</option>
          <option value="saab">Saab</option>
          <option value="mercedes">Mercedes</option>
          <option value="audi">Audi</option>
        </select>
        <div style={{position:"absolute", right:"0", display:"flex"}}>
          <div>Sort By</div> &nbsp;
          <select name="cars" id="cars">
            <option value="volvo"></option>
            <option value="saab">Saab</option>
            <option value="mercedes">Mercedes</option>
            <option value="audi">Audi</option>
          </select>
        </div>
        </div>

        <div className="presets_container hori_center">
        <div class="row">
        {currentPosts && currentPosts.map((val, key)=>{
          return (
            <div class="col-6 col-sm-4 col-md-3 mb-4 mob_p_card">
              <P_card item={val}/>
            </div>
          )
        })}

        {(!currentPosts) && 
          <div className="presets_loading" >

            <div style={{textAlign:"center"}} className="verti_center">
            <img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'"/>
            <div>Loading Presets ...</div>
            </div>
          </div>

        }

          </div>
          </div>
          {/* <div class="col-sm-3">
            <P_card/>
          </div>
          <div class="col-sm-3">
            <P_card/>
          </div>
          <div class="col-sm-3">
            <P_card/>
          </div> */}

          <br/><br/>
<div className="atk-pagination hori_center">
            <nav aria-label="Page navigation example" className="FIT_W">
              <ul class="pagination atk-ul">
                <li
                  class="page-item"
                  onClick={() => {
                    if (currentPage > 1) {
                      setCurrentPage(currentPage - 1);
                    }
                  }}
                >
                  <a class="page-link">Previous</a>
                </li>
                {pages.map((page, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        setCurrentPage(page);
                      }}
                      className={page == currentPage ? "atk-active" : ""}
                    >
                      <a
                        className={`page-link ${
                          page == currentPage ? "atk-active" : ""
                        }`}
                      >
                        {page}
                      </a>
                    </li>
                  );
                })}
                <li
                  class="page-item"
                  onClick={() => {
                    if (currentPage < pages.length) {
                      setCurrentPage(currentPage + 1);
                    }
                  }}
                >
                  <a class="page-link">Next</a>
                </li>
              </ul>
            </nav>
</div>

        



        
      <div>
        <Foot />
      </div>
    </div>
  );
}

export default Presets_main;
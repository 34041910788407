import './hero.css'
import suits from '../../assets/suits/main_bg1.png'
import suits1 from '../../assets/suits1.jpg'
import suits3 from '../../assets/suits/blezer.jpg'
import suits4 from '../../assets/suits/main_bg2.png'
import suits5 from '../../assets/suits/nav.jpg'
import suit56 from '../../assets/suits/suit6.jpg'

import emb from '../../assets/samples/bg.jpg'

import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom'
import { getState, getUser } from '../../Firebase/auth'
import Patti_home from './patti'
import Hero_text from './hero_text'

export default function Hero(){

    const navigate = useNavigate()

    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    

    const data = [{image:suits, caption:"1", description:"first"},
                    {image:suits5, caption:"2", description:"second"},
                    {image:emb, caption:"3", description:"Third"} ]

    return(
        <div className="Hero">
            
            <div className='hero-image-container'>
                    <Carousel activeIndex={index} onSelect={handleSelect} fade>
                        {data.map((slide, i) => {
                            return (
                            <Carousel.Item>
                            <img
                            className="hero-img"
                            src={slide.image}
                            alt="slider image"
                            />
                            
                        </Carousel.Item>
                            )
                        })}
                </Carousel>

            </div>

            {/* <div
                className='patti'
            >
                
                <label style={{"width":"fit-content"}} className='verti_center'>
                        {caps[active1]}
                </label>
                
            </div>
             */}
             <Patti_home />

            <div className='hero-full'></div>  
            <Hero_text/>
        </div>
    )
}

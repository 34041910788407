import { useEffect, useState } from "react";

import {signin, signinGoogle} from '../Firebase/auth'
import { getAuth } from "firebase/auth";

import google from '../assets/icon/google1.png'

import firebase from "../firebase";
import { useLocation, useNavigate } from "react-router-dom";

import login_vid from '../assets/login_vid.mp4'

function Login() {
    const navigate = useNavigate()
    const location = useLocation()
    const [redirect, setRedirect] = useState("/")
    const [state, setState] = useState(0)
    
    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

    useEffect(()=>{
        if(location && location.state && location["state"]["from"]){
            setRedirect(redirect+location.state["from"])
            
            // console.log(redirect+location.state["from"])
        }
    }, [])


    return ( 
    <div className="auth">
        <div style={{"width":"60vw", "height":"100vh", "position":"absolute", backgroundColor:"white"}} className="mobile_vid_login">
                        <video className='' autoPlay muted loop style={{"width":"100%", "height":"100%", "objectFit":"cover"}}>
                        <source src={login_vid} />
                        </video>
        </div>
        {state == 0 && (<div className={isMobile ? "login both_center" : "login verti_center"}
                
        >
            
            <div className="txt-font">Login into your Account</div>
            <img src={google} className="google_logo hori_center"
                type="button"
                onClick={()=>{
                    signinGoogle().then(()=>{
                        navigate(redirect)
                    }).catch((error)=>{console.log(error)})
                }}
            />
            
            <input placeholder="Email" className="L-email"/>
            <input placeholder="Password" className="L-pass"/>

            <button className="Butt1 hori_center"
                disabled = {true}
                onClick={()=>{
                    signin("raghavendra074743@gmail.com", "test@123").then(()=>{
                        navigate(redirect)                               
                    }).catch((error)=>{console.log(error)})
                }}
            ><span>Log in</span></button>
            <label style={{color:"white"}}>forgot your password ?</label>
            <div
                onClick={()=>{
                    setState(1)
                }}
                style={{color:"white"}}
            >Create Account</div>
        </div>)}
        {state == 1 && <div className="signup hori_center">
            <div className="txt-font">Create your Account</div>
            <input placeholder="Name" className="si-name"/>
            <input placeholder="Email" className="si-email"/>
            <input placeholder="set your password" className="si-p1"/>
            <input placeholder="Confirm Password" className="si-p2"/>
            <button className="Butt1 hori_center"
                onClick={()=>{

                }}
            >Signup</button>
            <div
                onClick={()=>{
                    setState(0)
                }}
            >Already have account ? Login</div>
        </div>}
    </div> 
    );
}

export default Login;
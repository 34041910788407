import { useNavigate } from "react-router-dom";

import './hero.css'
import { useEffect, useState } from "react";

function Hero_text() {

    const caps = ["Elevate Your Style", "Custom Tailoring,", "Tailored For You,"]
    const caps1 = ["with Tailored Elegance", "Endless Possibilities", "Designed by You"]
    const des = [
        "Crafting bespoke clothing that speaks your individuality.Upgrade your wardrobe with one-of-a-kind, custom-made pieces.",
        "Custom-made clothing that combines your vision with our precision for a flawless fit. Explore our premium fabric collections now.",
        "Say goodbye to complicated sizing! Follow our easy, step-by-step guide to measure yourself at home and get the perfect fit every time."
    ]

    const [active1, setActive1] = useState(0)
    useEffect(() => {
        setTimeout(
        () =>
            setActive1((prevIndex) =>
                prevIndex === caps.length - 1 ? 0 : prevIndex + 1
            ),
        5000
        );

        return () => {};
    }, [active1]);

    const navigate  =  useNavigate()

    return (  <div className='hero-txt'>   
        <label className='title-font hero_title mob-title'>
        {caps[active1]} <br/>
        {caps1[active1]}
        </label><br/>

        <div className='hero-des txt-font mob-text'>
            {des[active1]}
            {/* Crafting bespoke clothing that speaks your individuality.
            Upgrade your wardrobe with one-of-a-kind, custom-made pieces. */}
        </div>

        <div className='hero-buttons'>
            <button className='Butt2 txt-font1 '
                onClick={()=>{
                    navigate("/custom")
                }}
            >
                <span>Customize</span>
            </button>
            
            <button className='Butt1 txt-font1'
                onClick={()=>{
                    // console.log(getState())
                    // console.log(getUser())
                    // navigate("/custom")
                }}
            > 
                <span>Explore</span>
            </button>
        </div>
    </div>);
}

export default Hero_text;
import { useEffect, useState } from "react";
import CustomM from "./customM";

import mbg1 from '../assets/mbg1.jpg'
import mbg2 from '../assets/mbg2.jpg'
import SetMasurement from "./setMeasurements";
import { useNavigate } from "react-router-dom";

import aim from '../assets/samples/anima.gif'

import fImg from '../assets/samples/body.png'
import sImg from '../assets/samples/BODY1.png'


function Measurement({edit, nav, setNav, section, setSection, typeM}) {

    
    const [M, setM] = useState();

    const [useSection, setUseSection] = useState(0)
    const [useNav, setUseNav] = useState(nav)
    const navigate = useNavigate()

    useEffect(()=>{
        const data = sessionStorage.getItem("sel")
        if(!data){
            navigate("/")
        }
    }, [])
    useEffect(()=>{
        if(section){
            setUseSection(section)
        }
    }, [section])
    useEffect(()=>{
        if(nav){
            setUseNav(nav)
        }
    }, [nav])


    const [loading, setLoading] = useState(false)

    
    const [isMobile , setIsMobile] = useState(window.innerWidth <= 480);

    const [mState, setMState] = useState(0)

    const [picModal, setPicModal] = useState()

    // useEffect(()=>{
    //     const session = JSON.parse(sessionStorage.getItem('sel'))
    //     if(!session){
    //         navigate("/custom") 
    //     }
    // }, [])

    // function backMove () {
        
    //     if(nav === 2){setNav(1)}
        
    // }
    // useEffect(()=>{
    //     window.history.pushState( 'dummy', document.title, window.location.href )
    // }, [])
    // useEffect(()=>{
    //     if(nav === 2){window.history.pushState( 'dummy', document.title, window.location.href )

    //     window.addEventListener('popstate', backMove);
    //     console.log("someting from measurments :", nav)}

    //     return()=>{
    //         window.removeEventListener('popstate', backMove);

    //         if (window.history.state === 'dummy' && nav === 2) {
    //             window.history.back();
    //         }
        
    //     }

    // }, [])

    return ( 
        <div className="measurements"
            
            style={useSection === 0 && !isMobile ? { "top":"0" } : (isMobile && useSection === 0) ? {"top":"60px", height:"100svh"} :  {"top":"60px", height:"calc(90svh)" }}
        >
            <div
                type="button"
                className="backButton"
                style={useSection === 0 && !isMobile ? { "top":"10vh" } : isMobile ? { "top":"10px" }:{}}
                onClick={()=>{
                    
                    if(useSection === 2){
                        if(typeM){
                            setSection(0)
                        }else{
                            setUseSection(0)
                        }
                        
                    }else if(useSection === 1){
                        if(mState === 0){
                            if(typeM){
                                setSection(0)
                            }else{
                                setUseSection(0)
                            }
                        } else {
                            setMState(mState-1)
                        }
                    }
                    else{
                        if(typeM){
                            setNav(1)
                        }
                    }
                    
                }}
                
            > {"<"}  <b><u>Go Back </u></b></div>

            {picModal && !isMobile && 
            <div
                className="m-hover-img"
            >   
                <label style={{"width":"100%","padding":"5px", "background":"#14213D", "color":"white", "textAlign":"center"}}
                >
                    {picModal === 1? "Sample Front View Image" : "Sample Side View Image"}
                </label>
                <img src = { picModal === 1 ? fImg : sImg} 
                    style={{"width":"100%", "height":"100%", "objectFit":"contain"}}/>
                
            </div>

            }

            {loading && <div style={{"position":"fixed", "height":"100vh", "width":"100vw", "background":"rgb(0, 0, 0, 0.3)", "textAlign":"center", "top":"0", "left":"0", "zIndex":"1000"}}>
                <img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'" 
                    style={{"zIndex":"100001", "top":"40vh", "position":"absolute"}}
                />
                
            </div>}
            { useSection == 0 ? (
                <div style={{width:"100%", display:"flex", height:"100%", textAlign:"center"}}>

                    
                    <div className="m-home-mask"></div>
                    <div className="m-home-left "
                        style={{backgroundImage:`url(${mbg1})`}}
                    >
                        <div className="m-home-body both_center input1">
                            <div className="title-font1 m-home-di">Choose From <br/>Size Chart</div>
                            <div className="txt-font1 m-home-di">Easily find your ideal fit with our <br/>detailed size chart.</div>
                            <button className="Butt1 hori_center size-but"
                                onClick={()=>{
                                    window.history.pushState( 'dummy', document.title, window.location.href )
                                    
                                    if(typeM){
                                        setSection(2)
                                    }else{
                                        setUseSection(2)
                                    }
                                    // setSection(2)
                                }}
                            >
                                <span>Preset</span></button>
                        </div>
                    </div>
                    <div className="m-home-right"
                        style={{backgroundImage:`url(${mbg2})`}}
                    >
                        <div className="m-home-body both_center input2">
                            <div className="title-font1 m-home-di">Custom Measurements</div>
                            <div className="txt-font1 m-home-di">Measuring for your perfect fit has never been easier. <br/>Follow simple steps and get Measured</div>
                            <button className="Butt1 hori_center size-but"
                                onClick={()=>{
                                    window.history.pushState( 'dummy', document.title, window.location.href )
                                    if(typeM){
                                        setSection(1)
                                    }else{
                                        setUseSection(1)
                                    }
                                    
                                    // setSection(1)
                                }}
                            >
                                <span>Custom</span></button>
                        </div>
                    </div>
                </div>
            ) 
            : useSection == 1 ?  (<CustomM 
                                    M = {M}
                                    setM = {setM}
                                    edit = {edit}
                                    setLoading = {setLoading}
                                    setPicModal = {setPicModal}

                                    mState={mState}
                                    setMState={setMState}
                                    />) 
            : ( <SetMasurement
                    M = {M}
                    setM = {setM}
                    edit = {edit}
                    />)          
            }
        </div> 
    );
}

export default Measurement;
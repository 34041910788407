import { useContext, useEffect, useState } from "react";
import Cart from "./cart";
import './checkout.css'
import Cost from "./cost_details";
import Address from "./address";
import { db } from "../firebase";

import aim from '../assets/samples/anima.gif'

import DEL from '../assets/icon/del.png'

import close from '../assets/icon/close.png'

import measure from '../assets/samples/TAPE.png'

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Firebase/AuthProvider";
import Done from "./done";
import Terms from "../terms";



function Checkout() {

    const { user } = useContext(AuthContext);

    const navigate = useNavigate()
    const [page, setPage] = useState(0)
    const [change, setChange] = useState(0)

    const [checkoutData, setcheck] = useState({})

    const [payment, paymentStatus] = useState({"id": -100});

    const [order,setOrder] = useState()

    const [modal_item, setModalItem] = useState({"s":false})

    const [cartItems, setCart] = useState(JSON.parse(localStorage.getItem("sel")))

    const [terms, setTerms] = useState(false)

    const [ inCart, setInCart ] = useState()

    const senEmail = async (params)=>{
        
        const headers = {
            "Content-Type" : "application/json"
        }
        const resp = await fetch('https://suits-backend-jey7.vercel.app/sendMail', {method : 'POST',headers:headers,body:JSON.stringify(params)})
        const res = await resp.json()

        return res.status === 0 ? true : false


    }

    const postOrder = async()=>{

        try{
            console.log("POST Order")

            let orders = JSON.parse(localStorage.getItem('sel'))

            console.log("Orders" , orders)

            if(orders){ 
                // // console.log("Checking order details",order)
                for(let i = 0;i<orders.length;i+=1){
                    orders[i]["payment_order_id"] = order["razorpay_order_id"]
                    orders[i]["payment_id"] = order["razorpay_payment_id"]
                    orders[i]["Address"] = checkoutData["address"]
                }
                // console.log("dumping in DB", orders)
                
                const collection = db.collection('Orders')
                var batch = db.batch();
                // console.log("passing this")
                const email = user.email
                const curr = new Date()

                const OrderID = curr.getDate().toString()+"-"+curr.getTime().toString()
                var i = 0
                orders.forEach((doc) => {
                    i = i+1
                    const ID = (new Date().getTime()).toString()
                    // console.log("ID from orders, ", ID)
                    const finalOrder = {...doc, ...{"Date":Date(), "id":ID, "email":email, "orderId":OrderID}}
                    batch.set(collection.doc(ID), finalOrder);
                })
                const result = await batch.commit()
                
                paymentStatus({...payment, "id":1})
                localStorage.removeItem('sel')

                const pramsData = {
                    "email" : email,
                    "name" : user.displayName,
                    "orderId" :  OrderID ,
                    "items" : i.toString(),
                    "price" : checkoutData["price"]
                }
                setcheck({...checkoutData, ...pramsData})
                
                console.log("User Detaisl", pramsData)
                senEmail(pramsData).then(()=>{})
                // console.log("postOrder", result)
            }else{
                // console.log("No orders")
                paymentStatus({...payment, "id":-1})
                // return error
            }
        } catch(e){
            // console.log("Error",e)
            paymentStatus({...payment, "id":-1})
        }
    }

    function deleteFromCart(id, type = 0){
        // console.log("id received", id)
        let data = JSON.parse(localStorage.getItem("sel"))
        console.log("deleting", id);

        
        for(let i=0;i<data.length;i++){
            
            if(type === 0){

                if(data[i].id == id){
                    
                    if(data.length == 1){data = []}else{data.splice(i, 1)}
                    // console.log("After delete", data)
                    setInCart(data)
                    localStorage.setItem("sel", JSON.stringify(data))
                    setCart(data)
                    setChange((prev)=>!prev)
                    return true
                }
            } else {
                if(data[i].item_type === 0){
                    
                    let del_all = 0;
                    if(data[i].items.length == 1 && data.length === 1){
                        data = []
                    }else{
                        if(data[i].items.length == 1){
                            data.splice(i, 1)
                            del_all = 1;
                        } else {
                            for(let j=0;j<data[i].items.length;j++){
                                if(data[i].items[j].id === id){
                                    data[i].items.splice(j, 1)
                                }
                            }
                        }
                    }
                    console.log("After Data changes ", data)    
                    // console.log("After delete", data)
                    setInCart(data)
                    localStorage.setItem("sel", JSON.stringify(data))
                    setCart(data)
                    if(del_all === 1){
                        setModalItem({"s":false})
                    } else {

                    
                    setModalItem({...{
                        "s":"true"
                    }, ...data[i]})
                    }
                    setChange((prev)=>!prev)
                    return true
                }
                
            }
        }
        
        return false
    }

    useEffect(()=>{
        
        if(payment["id"] === 0 && payment["status"] === true){
            postOrder().then(()=>{})
        }else if(payment["id"] === 0 && payment["status"] === false){
            paymentStatus({
                "id": -1
            })
        }

    }, [payment])

    useEffect(()=>{
        let Data = JSON.parse(localStorage.getItem("sel"))
        let p = 0;
        let fab = 0;
        if(Data){

            for(let i=0;i<Data.length;i++){
                if(Data[i].price){
                    p+=Number(Data[i].price)
                }else{
                    fab+=1
                }
                
            }
            if(fab>1){
                p+=250
            }else{
                p+=100
            }

        }
        setcheck({"price":p+0.12*Number(p)})


    }, [])



    return ( 
    <div className="Checkout">

        {page === 0 && payment["id"] === -100 ? (
            <div className="cart-page">
                <Cart
                    setChange = {setChange}
                    modal_item = {modal_item}
                    setModalItem = {setModalItem}
                    cartItems = {cartItems} 
                    setCart = {setCart}
                    deleteFromCart = {deleteFromCart}
                    inCart = {inCart} 
                    setInCart = {setInCart}
                />
                <Cost
                    change = {change}
                    setChange = {setChange}

                    page = {page}
                    setPage= {setPage}

                    setTerms = {setTerms}
                />
            </div>
        ) 
        : payment["id"] === -100 ? (<div className="cart-page">
            <Address
                data={checkoutData}
                setData  ={setcheck}
                setPage ={setPage}

                payment = {payment}
                paymentStatus = {paymentStatus}
                setOrder = {setOrder}
            />
            <Cost
                change = {change}
                setChange = {setChange}
            />
    </div>) : (
        <div className="payment_confirm">
            {payment["id"] === 0 ? (
                <div className="payment_process both_center">
                    <img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'"/>
                    <h2>Please Wait</h2>
                    <h2>Processing your order</h2>       
                </div>) 
            : payment["id"] === 1 ? (
                <Done
                    data={checkoutData}
                />) 
            : payment["id"]===-1 ? (<div><br/><br/><br/>Payment Failed</div>) : (
            <div className="payment_process both_center">
                    <img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'"/>
                    <h2>Please Wait</h2>
                    <h2>Processing your order</h2>       
                </div>
            )

            }
        </div>
        // <h1> <br/><br/><br/>{payment === 0 ? `Payment Processing - ${payment}. Please wait ...` : payment === 1 ? `Payment Success ${payment}` : `Payment Failed ${payment}`} </h1>
    )}

    {((modal_item && modal_item["s"]) || terms) && <div className="opac_cart"
        onClick={()=>{
            setModalItem({"s":false})
            setTerms(false)
        }}
    ></div>}
    {modal_item && modal_item["s"] && <div className="fabric_cart_modal both_center">
        <div  className="f_c_m_W" style={{position:"relative", width:"100%", height:"100%"}}>
        
        <div className="txt-font1 cart_f_modal">
            <label className="FIT_W hori_center">Fabric Samples</label>
            <img src={close} style={{position:"absolute", "right":"0"}}
              onClick={()=>{setModalItem({"s":false})}}
            />
        </div>
        <br/>
        <div className="hori_center"
            style={{width:"80%", textAlign:"center"}}
        > 
            <div style={{display:"flex"}}>
                
                <label
                    style={{"width":"calc(100%-110px)"}} className="mob_f_des"
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</label>
                <img src={measure} style={{width:"100px"}}/>

            </div>

            <div style={{position:"relative"}}>
                <span><b>Fabrics { modal_item.items.length } /5 </b> </span>
                <span
                    style={{position:"absolute", "right":"0"}}
                >Total : { modal_item.items.length === 1 ? 100 : 250}</span> 
            </div>
            
        </div>

        <br/>


        <div className="fabric_cards_wrapper">
        <div className="hori_center fabrics_wrapper">
            {modal_item && modal_item["s"] && modal_item.items.map((val, ind)=>{
                return(
                    <div className='preset_fabric_details hori_center'>

                    
                    
                    

                    <div className='fab_p' style={{display:"flex"}}>
                        <img src={DEL} style={{width:"25px", position:"absolute", top:"5px", right:"5px"}}

                            onClick={()=>{
                                deleteFromCart(val.id, 1)
                            }}
                            type="button"
                        />

                        <div style={{"width":"35%", marginRight:"5%"}}><img src={
                             val["single"] && val["single"].length>0 ?val["single"][0] :val["src"]
                            } className='p_f_d_img'/></div>
                        <div style={{"width":"65%", display:"flex", flexDirection:"column"}}>
                            
                            <div className='FIT_H both_center'
                                style={{display:"flex", flexDirection:"column"}}
                            >
                            <div style={{display:"flex"}}>
                                <img src={val.brandImg} style={{"width":"80px"}}/>
                                <span className='p_fab_n'>{val.name}</span>
                            </div>
                            
                            <span>{val.material}</span>
                            <span>Pattern : {val.pattern}</span>
                            <span>Weave : {val.weave}</span>
                            <span>Width : {val.width}</span>
                            <span style={{fontSize:"0.6rem"}}> {val.des} </span>
                            </div>

                        </div>                        
                    </div>
                    
                </div>
                )
            })

            }
               </div>
            </div>
        </div>
    </div>}

    {terms && 
    <div className="terms_modal both_center">
        <div><b>Terms and Conditions</b></div><br/>
        <div style={{height:"65vh", overflowX:"hidden", "overflowY":"scroll"}}>
            <Terms/>
        </div>
    </div>

    }
        
    </div> );
}

export default Checkout;